// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false
};

export const BASE_URL_API = `https://api.eticketame.com`
export const BASE_URL = `https://eticketame.com`
export const stripeAPIKey = 'pk_test_51NQAAEGNXSAYunDVGZDTXze8ZkL4lU46BGnFED7vyBvYf1jCMTvZ2pty9cNFvFgayYg9wkq7zkGGHK8A2GNQsRV200vD8Oc0cc'


/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
